<template>
  <div class="
      auth-page-wrapper auth-bg-cover
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    ">
    <div class="bg-overlay"></div>
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <a href="index.html" class="d-block">
                          <img src="/assets/images/logo-light.png" alt="" height="25" />
                        </a>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i class="ri-double-quotes-l display-4 text-success"></i>
                        </div>

                        <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-indicators">
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0"
                              class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1"
                              aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2"
                              aria-label="Slide 3"></button>
                          </div>
                          <div class="
                              carousel-inner
                              text-center text-white-50
                              pb-5
                            ">
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">
                                " Waktumu terbatas, jadi jangan sia-siakan
                                dengan menjalani hidup orang lain. Jangan
                                terjebak oleh dogma – yaitu hidup dengan hasil
                                pemikiran orang lain. " <br />
                                <small>Steve Jobs</small>
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Dunia ini cukup untuk memenuhi kebutuhan
                                manusia, bukan untuk memenuhi keserakahan
                                manusia." <br />
                                <small>Mahatma Gandhi</small>
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Tidak apa-apa untuk merayakan kesuksesan tapi
                                lebih penting untuk memperhatikan pelajaran
                                tentang kegagalan. " <br />
                                <small>Bill Gates</small>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Selamat datang kembali !</h5>
                      <p class="text-muted">
                        Silahkan login untuk masuk ke aplikasi.
                      </p>
                    </div>

                    <div class="mt-4">
                      <form @submit.prevent="submit">
                        <div class="mb-3">
                          <label for="username" class="form-label">Email</label>
                          <input v-model="auth.email" type="text" class="form-control" placeholder="Enter email"
                            autofocus />
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <router-link :to="{ name: 'password.forgot-password' }" class="text-muted">Lupa password?
                            </router-link>
                          </div>
                          <label class="form-label" for="password-input">Password</label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input v-model="auth.password" :type="password_type" class="form-control pe-5"
                              placeholder="Enter password" />
                            <button @click="addonPassword()" class="
                                btn btn-link
                                position-absolute
                                end-0
                                top-0
                                text-decoration-none text-muted
                              " type="button">
                              <i class="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <label class="form-check-label" for="auth-remember-check">Ingatkan saya</label>
                        </div>

                        <div class="mt-4">
                          <button :disabled="load" class="btn btn-success w-100" type="submit">
                            <i class="bx font-size-16 align-middle me-2" :class="{
                              'bx-loader bx-spin': load,
                              'bx-lock-open-alt': !load,
                            }"></i>
                            Login
                          </button>
                        </div>
                        <div class="mt-5 text-center">
                          <p class="mb-0">Belum memiliki akun ? <router-link :to="{ name: 'user.register' }"
                              class="fw-semibold text-primary text-decoration-underline"> daftar disini</router-link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">&copy;2022 - {{ year }} {{ title }}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { endpoint } from "../../host.js";
import errorService from "../../services/error-service";
import moment from "moment";
import permissonService from '../../services/permisson-service.js';

export default {
  created() {
    this.year = moment().year();
    this.cekAuth();
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      password_type: "password",
      load: false,
      year: null,
      auth: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    cekAuth() {
      let user = permissonService.user();
      if (user) {
        if (permissonService.hasRole('admin')) {
            this.$router.push({
              name: "home",
            });
          } else {
            this.$router.push({
              name: "user.home",
            });
          }
      }
    },

    async submit() {
      try {
        this.load = true;
        let r = await axios.post(endpoint.login, this.auth);
        this.load = false;
        if (r.status == 200) {
          localStorage.setItem("user", JSON.stringify(r.data.data.user));
          localStorage.setItem("token", r.data.data.token);
          localStorage.setItem("permissions", JSON.stringify(r.data.data.permissions));
          localStorage.setItem("roles", JSON.stringify(r.data.data.roles));
          // console.log(r.data.data.roles[0]);
          if (r.data.data.roles[0] == 'admin') {
            this.$router.push({
              name: "home",
              params: {
                reload: true,
              },
            });
          } else {
            this.$router.push({
              name: "user.home",
              params: {
                reload: true,
              },
            });
          }
        }
      } catch (error) {
        this.load = false;
        console.log(error.response);
        errorService.displayError(error.response.data.message);
      }
    },

    addonPassword() {
      this.password_type == "password"
        ? (this.password_type = "text")
        : (this.password_type = "password");
    },
  },
};
</script>

<style>

</style>